export enum FeatureKey {
  Farm = 'farm',
  Pond = 'pond',
  Food = 'food',
  Growth = 'growth',
  Death = 'dead_shrimp',
  WaterQuality = 'water_quality',
  WaterMineral = 'water_mineral',
  WaterVibrio = 'water_vibrio',
  Sales = 'sales',
  Cost = 'cost',
  PdfSummary = 'pdf_summary',
  PdfDaily = 'pdf_daily',
  PdfFeatures = 'pdf_features',
}

export enum CRUDAction {
  Create = 0,
  Read = 1,
  Update = 2,
  Delete = 3,
}
