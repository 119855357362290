import { useUserInfo } from './queries'

export const useAllowFeatures = () => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((el) => {
    const isRead = el.crud_permission[1]
    return isRead
  })
  return features
}

export const usePermitByFeature = (key: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => p.feature.key === key)
  return feature?.crud_permission[1] ? true : false
}

export const usePermitByFeatures = (crud: number, keys: string[]) => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((p) => keys.includes(p.feature.key))
  const allow = features.every((f) => f.crud_permission[crud] === 1)
  return allow
}

export const usePermitSomeFeatures = (keys: string[]) => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((p) => keys.includes(p.feature.key))
  const allow = features.some((f) => f.crud_permission[1] === 1)
  return allow
}

export const usePermitToActionFeature = (actions: number[], featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => featureKey === p.feature.key)
  const allow = actions.every((a) => feature?.crud_permission[a] === 1)
  return allow
}

export const usePermitSomeActionToFeature = (actions: number[], featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => featureKey === p.feature.key)
  const allow = actions.some((a) => feature?.crud_permission[a] === 1)
  return allow
}

export const useGetCRUDByFeature = (featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => p.feature.key === featureKey)
  return feature?.crud_permission
}
