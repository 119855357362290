import { createCycleApi } from 'core/api/apiFactory'
import { selectedCycleIdState } from 'modules/pond/stores/atom'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

const useCycleApi = () => {
  const { farmId, pondId } = useParams<{ farmId: string; pondId: string }>()
  const cycleId = useRecoilValue(selectedCycleIdState)

  if (!farmId || !pondId ) throw new Error('Farm ID or Pond ID is missing')

  return createCycleApi({ farmId, pondId, cycleId })
}

export default useCycleApi
