import { BaseResponse, MessageResponse } from 'common/types/api'
import api from 'core/api'

import {
  ResetPasswordBody,
  LoginBody,
  LoginResponse,
  SendOTPBody,
  SignUpBody,
  VerifyOTPBody,
  VerifyOTPResponse,
  SignUpResponse,
} from './types'

export const login = (body: LoginBody) =>
  api.post<BaseResponse<LoginResponse>>('/signin', body, { withCredentials: true })

export const loginMember = (body: LoginBody) =>
  api.post<BaseResponse<LoginResponse>>('/member/signin', body, { withCredentials: true })

export const sendOTP = (body: SendOTPBody) =>
  api.post<MessageResponse>('/signup/send-sms-otp', body)

export const verifyOTP = (body: VerifyOTPBody) =>
  api.post<BaseResponse<VerifyOTPResponse>>('/signup/verify-sms-otp', body)

export const signUp = (body: SignUpBody) =>
  api.post<BaseResponse<SignUpResponse>>('/signup', body, { withCredentials: true })

export const resetPassword = (body: ResetPasswordBody) => api.post('/reset-password', body)

export const requestResetPasswordOTP = (body: SendOTPBody) =>
  api.post<MessageResponse>('/reset-password/send-sms-otp', body)

export const verifyResetPasswordOTP = (body: VerifyOTPBody) =>
  api.post<BaseResponse<VerifyOTPResponse>>('/reset-password/verify-sms-otp', body)

export const renewAccessToken = (refreshToken: string) =>
  api.post<BaseResponse<LoginResponse>>(
    '/renew/access-token',
    { refreshToken },
    { withCredentials: true }
  )

export const logout = (refreshToken: string) =>
  api.post<MessageResponse>('/signout', { refreshToken })
