import axios, { AxiosInstance } from 'axios'
import { snackbarProviderRef } from 'common/components/NotistackProvider'
import delay from 'common/utils/delay'
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage'
import { renewAccessToken } from 'modules/auth/api'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'modules/auth/constants'

let isRefreshing = false
let refreshPromise: Promise<any> | null = null

export default function applyInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    const token = getLocalStorage(ACCESS_TOKEN_KEY)

    let clonedConfig = { ...config }
    if (token) {
      clonedConfig.headers.Authorization = `Bearer ${token}`
    }

    return {
      ...clonedConfig,
      cancelToken: axios.CancelToken.source().token,
    }
  })


  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const { response, config } = err
      if (response) {
        const status = response.status

        if (status === 401 && !config._retry) {
          await delay(200)
          config._retry = true
          if (!isRefreshing) {
            isRefreshing = true

            refreshPromise = (async () => {
              try {
                await delay(200);
                const rf = getLocalStorage(REFRESH_TOKEN_KEY)
                const res = await renewAccessToken(rf)
                const {
                  data: { accessToken, refreshToken },
                } = res.data
                setLocalStorage(ACCESS_TOKEN_KEY, accessToken)
                setLocalStorage(REFRESH_TOKEN_KEY, refreshToken)
                config.headers.Authorization = `Bearer ${accessToken}`
                return accessToken
  
              } catch (error) {
                localStorage.clear()
                window.location.replace('/auth/login')
                throw error
  
              } finally {
                isRefreshing = false
                refreshPromise = null
              }
            })()
          }

          try {
            const newAccessToken = await refreshPromise;
            config.headers.Authorization = `Bearer ${newAccessToken}`;
            return instance(config);
          } catch (error) {
            return Promise.reject(error);
          }
        }

        const skipStatuses = [400, 401, 409, 403]
        if (!skipStatuses.includes(status)) {
          const errorMessage = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
          snackbarProviderRef.current?.enqueueSnackbar(errorMessage, {
            variant: 'error',
          })
        }

        if (status === 403) {
          const errorMessage = 'ขออภัย คุณไม่มีสิทธิ์การเข้าถึง'
          snackbarProviderRef.current?.enqueueSnackbar(errorMessage, {
            variant: 'default',
          })
        }
      }

      return Promise.reject(err)
    }
  )
}
