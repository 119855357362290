import { BaseResponse, RequestConfig } from 'common/types/api'
import axios from 'core/api'

import {
  ShrimpSalesType,
} from './types'

export const SALES_REQUEST_CONFIG: RequestConfig = {
  get: { method: 'GET', url: '/sales' },
  getSummary: { method: 'GET', url: '/sales/summary' },
  getPartials: { method: 'GET', url: '/sales/partials' },
  create: { method: 'POST', url: '/sales' },
  edit: { method: 'PATCH', url: '/sales' },
  delete: { method: 'DELETE', url: '/sales' },
  uploadImage: {method: 'POST', url: '/sales/image'}
}

export const getSalesTypes = () =>
  axios.get<BaseResponse<ShrimpSalesType[]>>('/sales-type')
