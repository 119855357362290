import AuthGuard from 'common/guards/AuthGuard'
// guards
import GuestGuard from 'common/guards/GuestGuard'
// import RoleBasedGuard from 'common/guards/RoleBasedGuard'
// layouts
import DashboardLayout from 'layouts/dashboard'
import GuestLayout from 'modules/landing/components/GuestLayout'
import LandingPage from 'modules/landing/pages/LandingPage'
import ShrimpPricePage from 'modules/landing/pages/ShrimpPricePage'
import ChangePhonePage from 'modules/user/pages/ChangePhonePage'
import FeedbackPage from 'modules/user/pages/FeedbackPage'
import ProfilePage from 'modules/user/pages/ProfilePage'
import ResetPasswordPage from 'modules/user/pages/ResetPasswordPage'
// import { Role } from 'modules/user/constants'
import { ElementType, Suspense, lazy } from 'react'
import { useRoutes, useLocation } from 'react-router-dom'

// components
import LoadingScreen from 'components/LoadingScreen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('/dashboard')

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestLayout>
          <LandingPage />
        </GuestLayout>
      ),
    },
    {
      path: '/shrimp-price',
      element: (
        <GuestLayout>
          <ShrimpPricePage />
        </GuestLayout>
      ),
    },
    // Authentication
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ForgetPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'admin',
          element: (
            // <RoleBasedGuard accessibleRoles={[Role.Owner, Role.Manager]}>
            <AdminPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'farms',
          element: (
            // <RoleBasedGuard accessibleRoles={[Role.Owner, Role.Manager]}>
            <AllFarmsPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'farms/:farmId',
          element: (
            // <RoleBasedGuard accessibleRoles={[Role.Owner, Role.Manager]}>
            <AllPondsPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'farms/:farmId/pond/:pondId',
          element: (
            // <RoleBasedGuard
            //   accessibleRoles={[Role.Owner, Role.Manager, Role.PondCaretaker]}
            //   userMustApproved
            // >
            <PondDetailPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'members',
          element: (
            // <RoleBasedGuard accessibleRoles={[Role.Owner]}>
            <MemberPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'access',
          element: (
            // <RoleBasedGuard accessibleRoles={[Role.Owner]}>
            <AccessPage />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'profile',
          element: (
            <AuthGuard>
              <ProfilePage />
            </AuthGuard>
          ),
        },
        {
          path: 'feedback',
          element: (
            <AuthGuard>
              <FeedbackPage />
            </AuthGuard>
          ),
        },
        {
          path: 'change-phone',
          element: (
            <AuthGuard>
              <ChangePhonePage />
            </AuthGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <AuthGuard>
              <ResetPasswordPage />
            </AuthGuard>
          ),
        },
        {
          path: 'shrimp-price',
          element: (
            <AuthGuard>
              <ShrimpPricePage />
            </AuthGuard>
          ),
        },
      ],
    },
    // General
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'settings',
          element: <SettingsMainPage />,
        },
      ],
    },
  ])
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('modules/auth/pages/Login')))
const Register = Loadable(lazy(() => import('modules/auth/pages/Register')))
const ForgetPassword = Loadable(lazy(() => import('modules/auth/pages/ForgetPassword')))
// // Dashboard
const AdminPage = Loadable(lazy(() => import('modules/admin/pages/AdminMainPage')))
const AllFarmsPage = Loadable(lazy(() => import('modules/farm/pages/AllFarmsPage')))
const AllPondsPage = Loadable(lazy(() => import('modules/pond/pages/AllPondsPage')))
const PondDetailPage = Loadable(lazy(() => import('modules/pond/pages/PondDetailPage')))
const MemberPage = Loadable(lazy(() => import('modules/member/pages/MemberPage')))
const AccessPage = Loadable(lazy(() => import('modules/member/pages/AccessPage')))
// General
const SettingsMainPage = Loadable(lazy(() => import('modules/settings/pages/SettingsMainPage')))
