import { EmployeeTableFilter } from 'modules/member/api/types'
import { atom } from 'recoil'

export const DEFAULT_EMPLOYEE_TABLE_FILTER = {
  name: '',
  // farm: '',
  // responsibility: '',
  // role: '',
}

export const employeeTableFilterState = atom<EmployeeTableFilter>({
  key: 'employeeTableFilterState',
  default: DEFAULT_EMPLOYEE_TABLE_FILTER,
})

export const searchFarmGroupState = atom<string | undefined>({
    key: 'searchFarmGroupState',
    default: ''
})
