import axios from 'axios'
import { API_BASE_URL } from 'env'

import applyInterceptors from './interceptors'

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/v3`,
  headers: {
    'Content-Type': 'application/json',
  },
})

applyInterceptors(axiosInstance)

export default axiosInstance
