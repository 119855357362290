import axios from 'axios'
import { BaseCycleParams, BasePondParams } from 'modules/pond/api/types'

import api from '.'
import applyInterceptors from './interceptors'

const createFarmApi = (farmId: string) => {
  if (!farmId) throw new Error('Farm ID is required')

  const instance = axios.create({
    ...api.defaults,
    baseURL: `${api.defaults.baseURL}/farms/${farmId}`,
  })

  applyInterceptors(instance)
  return instance
}

const createPondApi = ({ farmId, pondId }: BasePondParams) => {
  if (!farmId || !pondId) throw new Error('Farm ID and Pond ID is required')

  const instance = axios.create({
    ...api.defaults,
    baseURL: `${api.defaults.baseURL}/farms/${farmId}/ponds/${pondId}`,
  })

  applyInterceptors(instance)
  return instance
}

const createCycleApi = ({ farmId, pondId, cycleId }: BaseCycleParams) => {
  if (!farmId || !pondId) throw new Error('Farm ID and Pond ID is required')
    
  const instance = axios.create({
    ...api.defaults,
    baseURL: `${api.defaults.baseURL}/farms/${farmId}/ponds/${pondId}/cycles/${cycleId}`,
  })

  applyInterceptors(instance)
  return instance
}

export { createFarmApi, createPondApi, createCycleApi }
