import { createSlice } from '@reduxjs/toolkit'

// @types
import { Mail, MailLabel } from '../../@types/mail'
// utils
import axios from '../../core/api'
import { dispatch } from '../store'

// ----------------------------------------------------------------------

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current
    return accumulator
  }, {})
}

type MailState = {
  isLoading: boolean
  error: boolean
  mails: {
    byId: Record<string, Mail>
    allIds: string[]
  }
  labels: MailLabel[]
}

const initialState: MailState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: [],
}

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false
      state.labels = action.payload
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload

      state.isLoading = false
      state.mails.byId = objFromArray(mails)
      state.mails.allIds = Object.keys(state.mails.byId)
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload

      state.mails.byId[mail.id] = mail
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id)
      }
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getLabels() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.get('/api/mail/labels')
      dispatch(slice.actions.getLabelsSuccess(response.data.labels))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------

export function getMails(params: Record<string, string>) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.get('/api/mail/mails', { params })
      dispatch(slice.actions.getMailsSuccess(response.data.mails))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------

export function getMail(mailId: string) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.get('/api/mail/mail', {
        params: { mailId },
      })
      dispatch(slice.actions.getMailSuccess(response.data.mail))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
