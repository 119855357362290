// material
import { SxProps, Theme } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'

// components
import Scrollbar from '../../../components/Scrollbar'
import TableBodyComponent from './TableBodyComponent'
import TableHeadComponent from './TableHeadComponent'
import { Column, GroupColumn } from './types'
import useInfiniteScrollTable from './useInfiniteScrollTable'

export interface BaseTableProps {
  columns: Column[]
  onLoading: () => void
  groupColumns?: GroupColumn[]
  isLoading?: boolean
  maxHeight?: number
  minHeight?: number
}

interface BasicTableProps extends BaseTableProps {
  rows: any[]
  onStyleCell?: (row: any) => SxProps<Theme>
}

export default function BasicTable({
  columns,
  rows,
  onLoading,
  groupColumns,
  isLoading = false,
  maxHeight = 420,
  minHeight = 420,
  onStyleCell,
}: BasicTableProps) {
  const handleScroll = useInfiniteScrollTable({ isLoading, onLoading })

  return (
    <Scrollbar>
      <TableContainer
        sx={{ maxHeight: `${maxHeight}px`, minHeight: `${minHeight}px` }}
        onScroll={handleScroll}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: 'fixed',
          }}
        >
          <TableHeadComponent columns={columns} groupColumns={groupColumns} />
          <TableBodyComponent
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            onStyleCell={onStyleCell}
          />
        </Table>
      </TableContainer>
    </Scrollbar>
  )
}
